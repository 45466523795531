import { Commit, Dispatch } from "vuex";

// Actions:
export const FETCH_PAGE = "fetchPage";
export const FETCH_PAGES = "fetchPages";
export const LOGIN = "login";
export const LOGOUT = "logout";
export const REGISTER = "register";
export const UPDATE_USER = "updateUser";
export const CHECK_AUTH = "checkAuth";
export const CHANGE_PASSWORD = "changePassword";
export const CHANGE_PROFILE = "changeProfile";
export const CHANGE_EMAIL = "changeEmail";
export const RESET_PASSWORD_SEND_EMAIL = "resetPasswordSendEmail";
export const RESET_PASSWORD = "resetPassword";
export const USER_ACTIVATE = "userActivate";
export const FETCH_ACCESS_TOKEN = "fetchAccessToken";
export const SET_USER_LANGUAGE = "initUserLanguage";

// Mutations:
export const FETCH_END = "setPages";
export const FETCH_START = "setLoading";
export const PURGE_AUTH = "logOut";
export const SET_PAGE = "setPage";
export const SET_AUTH = "setAuth";
export const SET_TOKEN = "setToken";
export const SET_ERROR = "setError";
export const RESET_STATE = "resetModuleState";
export const INIT_FAVOURITES = 'initFavourites';
export const SET_FAVOURITES = 'setFavourites';
export const ADD_FAVOURITE_USER = 'addFavouriteUser';
export const REMOVE_FAVOURITE_USER = 'removeFavouriteUser';
export const ADD_FAVOURITE = 'addFavourite';
export const REMOVE_FAVOURITE = 'removeFavourite';
export const PURGE_USER = 'purgeUser';

// Actions - Chat:
export const LIST_THREADS = 'listThreads';
export const RETRIEVE_THREAD = 'retrieveThread';
export const CREATE_MESSAGE = 'createMessage';
export const INIT_USER = 'initUser';

// Mutations - Chat:
export const SET_THREAD = 'setThread';
export const PAGINATE_THREAD = 'paginateThread';
export const READ_THREAD = 'readThread';
export const UPDATE_THREAD = 'updateThread';
export const DELETE_THREAD = 'deleteThread';
export const SET_THREADS = 'setThreads';
export const SET_MESSAGE = 'setMessage';
export const PURGE_CHAT = 'purgeChat';
export const SET_USER = 'setUser';

// Interfaces:
export interface ICommitAction<State> {
    commit: Commit;
    state: State;
  }
  
  export interface IComposeAction<State> {
    commit: Commit;
    dispatch: Dispatch;
    getters?: any;
    state: State;
  }