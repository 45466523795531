import { runFetch } from './request';
import { IFavourite, IResponseMessage } from '@/types';

export async function createFavourite(form: FormData): Promise<IFavourite> {
    const result = await runFetch('favourites/', {body: form, method: 'POST'}, true);

    return result;
}

export async function deleteFavourite(profileId: number): Promise<IResponseMessage> {
    const result = await runFetch(`favourites/remove/?profile_id=${profileId}`, {method: 'DELETE'}, true);

    return result;
}