import { listThreads, retrieveThread, createMessage, patchThreadRead } from "@/services/threads";
import {
  // - Actions    
  LIST_THREADS,
  RETRIEVE_THREAD,
  // - Mutations
  SET_THREAD,
  PAGINATE_THREAD,
  UPDATE_THREAD,
  DELETE_THREAD,
  SET_THREADS,
  // - Interfaces
  IComposeAction,
  CREATE_MESSAGE,
  SET_MESSAGE,
  PURGE_CHAT,
  READ_THREAD
} from "./storeTypes";
import { IChatState, IThread, IMessage, IRetrieveThreadPayload, ISetThreadPayload, ISendMessage, IUpdateThreadPayload } from "@/types";

const state: IChatState = {
  threads: [],
  messages: {},
};

const getters = {
  getThreads(state: IChatState) {
    return state.threads;
  },
  getMessages(state: IChatState) {
    return state.messages;
  },
  getThreadById: (state: IChatState) => (id: number): IThread | undefined => {
    return state.threads.find((thread: IThread) => thread.receiver_id === id);
  },
  getFilteredThreads: (state: IChatState) => (filter: string) => {
    return (filter
      ? state.threads.filter(thread => thread.display_name.indexOf(filter) > -1)
      : state.threads).sort((x: IThread, y: IThread) => (x.has_unread === y.has_unread) ? 0 : x.has_unread ? -1 : 1).sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf());
  },
  getMessageListById: (state: IChatState) => (id: number) => {
    return state.messages[id];
  },
  getPaginationById: (state: IChatState) => (id: number) => {
    return state.messages[id] ? Math.ceil(state.messages[id].length / 10) : 1;
  }
};

const actions = {
  async [LIST_THREADS]({ commit }: IComposeAction<IChatState>) {
    try {
      const threads: IThread[] = await listThreads();
      commit(SET_THREADS, threads);
    } catch (error) {
      throw new Error(`Error LIST_THREADS threads ${error}`);
    }
  },
  async [RETRIEVE_THREAD]({ commit, getters }: IComposeAction<IChatState>, { receiver_user, pagination }: IRetrieveThreadPayload) {
    const thread = getters.getThreadById(receiver_user);

    // If a thread does not exist (not retrieved from backed) then no actions should be preformed:
    if (!thread) {
      return;
    }

    const messageList = getters.getMessageListById(receiver_user);
    const messageListPagination = getters.getPaginationById(receiver_user);
    const shouldPreventThreadUpdate = !thread.has_unread && (messageList && messageListPagination >= pagination);
    // Prevent request if there are no unread messages, and the user has reached the end of the messages list
    if (shouldPreventThreadUpdate) {
      return;
    }

    try {
      // If there are unread messages in a thread, then an update to the latest 10 messages should be preformed:
      if (thread.has_unread) {
        const defaultPagination = 1;
        const messages: IMessage[] = await retrieveThread(receiver_user, defaultPagination);
        await patchThreadRead(receiver_user);
        commit(SET_THREAD, { receiver_user, messages });
        commit(READ_THREAD, receiver_user);
        return;
      }

      // If a user wants to see his chat history, then we would make a request to the backend for older messages on given pagination from the view:
      const messages: IMessage[] = await retrieveThread(receiver_user, pagination);
      commit(PAGINATE_THREAD, { receiver_user, messages });

    } catch (error) {
      throw new Error(`Error RETRIEVEING messages ${error}`);
    }
  },
  async [CREATE_MESSAGE]({ commit }: IComposeAction<IChatState>, messageDetails: FormData) {
    try {
      const message: IMessage = await createMessage(messageDetails);
      commit(SET_MESSAGE, { message, receiver_user: messageDetails.get('receiver') });
    } catch (error) {
      throw new Error(`Error SENDING message ${error}`);
    }
  }
};

const mutations = {
  [PAGINATE_THREAD](state: IChatState, payload: ISetThreadPayload) {
    const messages = payload.messages.reverse();
    state.messages[payload.receiver_user] = [...messages, ...(state.messages[payload.receiver_user] || [])];
  },
  [SET_THREAD](state: IChatState, { receiver_user, messages }: ISetThreadPayload) {
    // Create a set of the message IDs in the new messages array
    const newMessageIds = new Set(messages.map(message => message.id));

    // Convert the state.messages[receiver_user] object to an array and filter it to only include messages with IDs that are not in the new messages array
    const existingMessages = (state.messages[receiver_user] || []).filter(message => !newMessageIds.has(message.id));
    // Concatenate the new messages array to the beginning of the filtered existing messages array
    state.messages[receiver_user] = existingMessages.concat(messages.reverse());
  },
  [READ_THREAD](state: IChatState, receiver_user: number) {
    const thread = state.threads.find((thread) => thread.receiver_id === receiver_user);
    if (thread) thread.has_unread = false;
  },
  [UPDATE_THREAD](state: IChatState, payload: IUpdateThreadPayload) {
    // get info about latest message:
    const messages = state.messages[payload.receiver_user];
    const latestMessage = messages[messages.length - 1];

    // update latest message in a thread:
    const thread = state.threads.find((thread) => thread.receiver_id === Number(payload.receiver_user));
    if (thread) {
      Object.assign(thread, { timestamp: latestMessage.timestamp, latest_message: latestMessage.message });
      return;
    }

    // create thread if such is not found:
    const newThread: IThread = {
      display_avatar: payload.display_avatar,
      display_name: payload.display_name,
      has_unread: false,
      latest_message: latestMessage.message,
      max_pages: 1,
      receiver_id: Number(payload.receiver_user),
      timestamp: latestMessage.timestamp as Date
    };

    state.threads = state.threads.concat(newThread);
  },
  [DELETE_THREAD](state: IChatState, receiver_user: number) {
    state.threads = state.threads.filter((thread) => thread.receiver_id !== receiver_user);
    state.messages = Object.keys(state.messages).filter((key) => Number(key) !== receiver_user).reduce((messages, key) => Object.assign(messages, { [key]: state.messages[+key] }), {});
  },
  [SET_THREADS](state: IChatState, payload: IThread[]) {
    if (payload && payload.length) {
      state.threads = payload.sort((a, b) => new Date(b.timestamp).valueOf() - new Date(a.timestamp).valueOf());
    }
  },
  [SET_MESSAGE](state: IChatState, payload: ISendMessage) {
    // default set
    if (!state.messages[payload.receiver_user]) {
      state.messages[payload.receiver_user] = [];
    }

    // append message:
    state.messages[payload.receiver_user] = state.messages[payload.receiver_user].concat(payload.message);
  },
  [PURGE_CHAT](state: IChatState) {
    state.threads = [];
    state.messages = {};
  }
};

const store = {
  state,
  actions,
  mutations,
  getters
};

export default store;