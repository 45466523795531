import { IUser, ILoginCompleted } from '@/types';
import { runFetch } from './request';

export async function getUser(token: string): Promise<IUser> {
  if (!token) {
    const message = `Get user requires an authorization token!`;
    throw new Error(message);
  }

  const headers = new Headers();
  headers.append("Authorization", `Token ${token}`);
  
  const result: IUser = await runFetch('users/me/', {method: 'GET', headers});

  return result;
}

export async function postLogin(form: FormData): Promise<ILoginCompleted> {
  const result: ILoginCompleted = await runFetch('token/login/', {method: 'POST', redirect: 'follow', body: form});
  // const result: ILoginCompleted = await runFetch('chat/test/', {method: 'POST', body: form});
  
  return result;
}