import { runFetch } from './request';
import { IUsersWithPagination, UserProfile, Response } from '@/types';

export async function getUsersWithPagination(page: number): Promise<IUsersWithPagination> {
  const result: IUsersWithPagination = await runFetch(`profiles/?page=${page}`);

  return result;
}

/**
 * @param ids Comma separated IDs, i.e. '3,4,5'
 */
export async function getUsersById(ids: string) {
  const result = await runFetch(`profiles/?ids=${ids}`);

  return result;
}

export async function getUserWithProfile(id: number): Promise<UserProfile> {
  const result: UserProfile = await runFetch(`profiles/${id}`);
  return result;
}

export async function updateUserProfile(form: FormData): Promise<Response<UserProfile>> {
  const result = await runFetch('profile/', { method: 'PATCH', body: form }, true);
  return result;
}

export async function getUserProfile(): Promise<Response<UserProfile>> {
  const result = await runFetch('profile/', { method: 'GET' }, true);
  return result;
}

export async function searchUserProfiles(queryParameters: any) {
  const params: any = Object.keys(queryParameters)
    .filter(param => queryParameters[param])
    .map(param =>`${param}=${queryParameters[param]}`).join('&');

  const result = await runFetch(`search/profiles/?${params}`);
  return result;
}
