
import {
    IonMenu,
    IonHeader,
    IonToolbar,
    IonTitle,
    IonContent,
    IonImg,
    IonList,
    IonSelect,
    IonSelectOption,
    IonItem,
    IonAvatar,
    IonIcon,
    IonLabel,
    IonSplitPane,
    IonButton
} from "@ionic/vue";
import { useIonRouter } from '@ionic/vue';
import { defineComponent, computed, ref } from "vue";
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import { useLocalStorage } from "@/composables/localStorage";
import { handleErrors } from '@/composables/handleErrors';
import { LOGOUT } from "@/store/storeTypes";
import i18n from '@/i18n';
import {
    personCircleOutline,
} from "ionicons/icons";

export default defineComponent({
    name: "app-side-menu",
    components: {
        IonMenu,
        IonHeader,
        IonToolbar,
        IonTitle,
        IonContent,
        IonImg,
        IonList,
        IonSelect,
        IonSelectOption,
        IonAvatar,
        IonIcon,
        IonItem,
        IonLabel,
        IonButton,
        IonSplitPane,
    },
    setup() {
        const store = useStore();
        const localSorage = useLocalStorage();
        const router = useIonRouter();
        const { t } = useI18n();
        const { openToast } = handleErrors();

        const menuRef = ref<typeof IonMenu | null>(null);
        const isLoggedIn = computed(() => store.getters.isAuthenticated);
        const userProfile = computed(() => store.getters.getCurrentUserProfile);
        const menuItems = [
            {
                details: {
                    name: 'TermsOfUse',
                    url: '/privacy'
                }
            },
            {
                details: {
                    name: 'Contact',
                    url: '/contact'
                }
            },
        ];

        const userLogout = async () => {
            await store.dispatch(LOGOUT);

            router.replace({ path: '/login' })
            menuRef.value?.$el.close();
            openToast(t('LogoutSuccessful'), 'success');
        };

        const changeLanguage = async (test: any) => {
            i18n.global.locale.value = test.detail.value
            await localSorage.set('language', i18n.global.locale.value);
        };

        const openPage = (url: string) => {
            router.push({ path: url });
            menuRef.value?.$el.close();
        };

        return { userProfile, isLoggedIn, menuItems, menuRef, personCircleOutline, userLogout, changeLanguage, openPage };
    },
});
