import { toastController } from "@ionic/vue";
import i18n from '../i18n';

export interface IErrorHandlers {
    openToast(message: string, color?: string, duration?: number): void
    errorFilter(error: string[]): string
}

/**
 * Reusable functions for handling fetch errors integrated with state
 * @returns IErrorHandlers
 */
export function handleErrors(): IErrorHandlers {
    // Error parser:
    const errorFilter = (error: string[]) => error[0];

    // Show message:
    const openToast = async (message: string, color = 'warning', duration = 2000) => {
        const { t, te } = i18n.global;
        if (te(message)) {
            message = t(message);
        }
        
        const toast = await toastController.create({
            color,
            message,
            position: "top",
            duration: duration,
        });
        return toast.present();
    };

    return { errorFilter, openToast };
}