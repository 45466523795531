
import {
  IonIcon,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonPage,
  IonContent,
  IonRouterOutlet,
} from "@ionic/vue";
import {
  searchOutline,
  heartOutline,
  mailOutline,
  mailUnreadOutline,
  personOutline,
} from "ionicons/icons";
import { defineComponent, computed, watch } from "vue";
import { useStore } from "vuex";
import { useNewMessages } from "@/composables/newMessages";

export default defineComponent({
  name: "tabs-root",
  components: {
    IonIcon,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonPage,
    IonContent,
    IonRouterOutlet,
  },
  setup() {
    const store = useStore();
    const isLoggedIn = computed(() => store.getters.isAuthenticated);

    const { setupMessagesInterval, clearMessagesInterval, hasUnread } = useNewMessages();
    watch(() => isLoggedIn.value, (userIsAuthenticated: boolean) => {
      if (!userIsAuthenticated) {
        clearMessagesInterval();

        return;
      }

      setupMessagesInterval();
    });

    if (isLoggedIn.value) {
      setupMessagesInterval();
    }

    return {
      hasUnread,
      searchOutline,
      heartOutline,
      mailOutline,
      mailUnreadOutline,
      personOutline,
      isLoggedIn,
    };
  },
});
