import { toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, renderSlot as _renderSlot } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ion_title = _resolveComponent("ion-title")!
  const _component_ion_toolbar = _resolveComponent("ion-toolbar")!
  const _component_ion_header = _resolveComponent("ion-header")!
  const _component_ion_img = _resolveComponent("ion-img")!
  const _component_ion_icon = _resolveComponent("ion-icon")!
  const _component_ion_avatar = _resolveComponent("ion-avatar")!
  const _component_ion_label = _resolveComponent("ion-label")!
  const _component_ion_button = _resolveComponent("ion-button")!
  const _component_ion_item = _resolveComponent("ion-item")!
  const _component_ion_select_option = _resolveComponent("ion-select-option")!
  const _component_ion_select = _resolveComponent("ion-select")!
  const _component_ion_list = _resolveComponent("ion-list")!
  const _component_ion_content = _resolveComponent("ion-content")!
  const _component_ion_menu = _resolveComponent("ion-menu")!
  const _component_ion_split_pane = _resolveComponent("ion-split-pane")!

  return (_openBlock(), _createBlock(_component_ion_split_pane, {
    when: "md",
    contentId: "main"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_ion_menu, {
        ref: "menuRef",
        "content-id": "main"
      }, {
        default: _withCtx(() => [
          _createVNode(_component_ion_header, null, {
            default: _withCtx(() => [
              _createVNode(_component_ion_toolbar, { translucent: "" }, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_title, null, {
                    default: _withCtx(() => [
                      _createTextVNode(_toDisplayString(_ctx.$t('Menu')), 1)
                    ]),
                    _: 1
                  })
                ]),
                _: 1
              })
            ]),
            _: 1
          }),
          _createVNode(_component_ion_content, null, {
            default: _withCtx(() => [
              (_ctx.isLoggedIn)
                ? (_openBlock(), _createBlock(_component_ion_item, {
                    key: 0,
                    button: "",
                    detail: false,
                    lines: "full"
                  }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_avatar, { slot: "start" }, {
                        default: _withCtx(() => [
                          (_ctx.userProfile?.avatar)
                            ? (_openBlock(), _createBlock(_component_ion_img, {
                                key: 0,
                                alt: "User avatar",
                                src: _ctx.userProfile.avatar
                              }, null, 8, ["src"]))
                            : (_openBlock(), _createBlock(_component_ion_icon, {
                                key: 1,
                                icon: _ctx.personCircleOutline,
                                class: "message-avatar",
                                color: "primary"
                              }, null, 8, ["icon"]))
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_label, { class: "ion-padding-vertical" }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.userProfile?.user?.username), 1)
                        ]),
                        _: 1
                      }),
                      _createVNode(_component_ion_button, {
                        slot: "end",
                        fill: "clear",
                        size: "small",
                        color: "secondary",
                        button: "",
                        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.userLogout()))
                      }, {
                        default: _withCtx(() => [
                          _createTextVNode(_toDisplayString(_ctx.$t('LogOut')), 1)
                        ]),
                        _: 1
                      })
                    ]),
                    _: 1
                  }))
                : _createCommentVNode("", true),
              _createVNode(_component_ion_list, null, {
                default: _withCtx(() => [
                  _createVNode(_component_ion_item, { lines: "inset" }, {
                    default: _withCtx(() => [
                      _createVNode(_component_ion_select, {
                        onIonChange: _ctx.changeLanguage,
                        value: _ctx.$i18n.locale,
                        placeholder: _ctx.$t('Language'),
                        "cancel-text": _ctx.$t('Cancel')
                      }, {
                        default: _withCtx(() => [
                          _createVNode(_component_ion_select_option, { value: "bg" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('Bulgarian')), 1)
                            ]),
                            _: 1
                          }),
                          _createVNode(_component_ion_select_option, { value: "en" }, {
                            default: _withCtx(() => [
                              _createTextVNode(_toDisplayString(_ctx.$t('English')), 1)
                            ]),
                            _: 1
                          })
                        ]),
                        _: 1
                      }, 8, ["onIonChange", "value", "placeholder", "cancel-text"])
                    ]),
                    _: 1
                  }),
                  (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.menuItems, (page, index) => {
                    return (_openBlock(), _createBlock(_component_ion_item, {
                      key: index,
                      lines: "inset",
                      button: "",
                      detail: "",
                      onClick: ($event: any) => (_ctx.openPage(page.details.url))
                    }, {
                      default: _withCtx(() => [
                        _createVNode(_component_ion_label, { class: "ion-padding-vertical" }, {
                          default: _withCtx(() => [
                            _createTextVNode(_toDisplayString(_ctx.$t(page.details.name)), 1)
                          ]),
                          _: 2
                        }, 1024)
                      ]),
                      _: 2
                    }, 1032, ["onClick"]))
                  }), 128))
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      }, 512),
      _renderSlot(_ctx.$slots, "default", {}, undefined, true)
    ]),
    _: 3
  }))
}