import { createRouter, createWebHistory } from '@ionic/vue-router';
import { RouteRecordRaw } from 'vue-router';
import TabsRoot from '@/components/TabsRoot.vue';
import { useLocalStorage } from "@/composables/localStorage"
// import store from '@/store';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: '/tabs/home'
  },
  {
    path: '/resetPassword/:id/:token',
    component: () => import('@/views/securityPanel/ResetPassword.vue')
  },
  {
    name: 'ActivateAccount',
    path: '/activateAccount/:email?',
    component: () => import('@/views/securityPanel/ActivateAccount.vue')
  },
  {
    path: '/tabs/',
    component: TabsRoot,
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('@/views/detailsPanel/Home.vue')
      },
      {
        path: 'favourites',
        name: 'favourites',
        component: () => import('@/views/favouritesPanel/Favourites.vue')
      },
      {
        path: 'home',
        component: () => import('@/views/detailsPanel/Home.vue')
      },
      {
        name: 'register',
        path: '/register',
        component: () => import('@/views/Register.vue')
      },
      {
        name: 'login',
        path: '/login/:id?/:token?',
        component: () => import('@/views/Login.vue'),
      },
      {
        name: 'details',
        path: 'details/:id',
        component: () => import('@/views/detailsPanel/ContragentInfo.vue')
      },
      {
        name: 'userProfile',
        path: 'userProfile',
        component: () => import('@/views/userPanel/UserProfile.vue')
      },
      {
        name: 'messages',
        path: 'messages',
        component: () => import('../views/messagesPanel/Messages.vue')
      },
      {
        name: 'privacy',
        path: '/privacy',
        component: () => import('../views/Privacy.vue')
      },
      {
        name: 'contact',
        path: '/contact',
        component: () => import('../views/Contact.vue')
      },
    ]
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
})

const GUARDED_PAGES = ['favourites', 'userProfile', 'messages'];
router.beforeEach(async (to) => {
  const storage = useLocalStorage();
  const isAuthenticated = await storage.get("authToken");
  // const isAuthenticated = store.getters.isAuthenticated;

  // Handle login page:
  if (isAuthenticated && to.name === 'login') {
    return { name: 'userProfile' }
  }

  // Handle guarded pages:
  const isGuardedPage = GUARDED_PAGES.find((page: string) => page === to.name);
  if ((isGuardedPage && !isAuthenticated) && to.name !== 'login') {
    // redirect the user to the login page
    return { name: 'login' }
  }

  return true;
})

export default router
