import { createI18n } from 'vue-i18n'
const messages = {
  bg:{
  "NewGenerationAdvertising": "Ново поколение реклама",
  "ReachTheActiveAudience": "Достигнете до активната аудитория",
  "Home":"Начало",
  "TermsOfUse":"Поверителност и условия за ползване",
  "Language":"Език",
  "Menu":"Меню",
  "LogOut":"Изход",
  "Bulgarian":"Български",
  "English":"Английски",
  "Cancel":"Откажи",
  "FilterBySocialMedia": "Филтрирай по социална мрежа",
  "CreateAccount": "Създаване на акаунт",
  "ForgotYourPassword": "Забравена парола?",
  "SignUp": "Регистрирай се",
  "LogIn": "Влез",
  "DontHaveAnAccount": "Нямате акаунт?",
  "Password": "Парола",
  "Email": "Имейл",
  "ThisIsYou": "Това сте вие 🙂",
  "Profession": "Професия",
  "Bio": "Накратко за мен",
  "PageType": "Социална мрежа",
  "Followers": "Последователи",
  "Link": "Линк",
  "Price": "Цена за реклама(лв)",
  "PageDescription": "Описание на страницата",
  "AlphaNum": "Полето не трябва да съдържа само числа",
  "FirstName": "Име",
  "LastName": "Фамилия",
  "CurrentPassword": "Текуща парола",
  "NewPassword": "Нова парола",
  "ConfirmNewPassword": "Потвърдете новата парола",
  "CurrentEmail": "Текущ имейл",
  "NewEmail": "Нов имейл",
  "ConfirmNewEmail": "Потвърдете новия имейл",
  "PasswordReset": "Създаване на нова парола",
  "Send": "Изпрати",
  "Username": "Потребителско име",
  "RepeatPassword": "Повторете паролата",
  "UseDigitsAndLetters": "Използвайте цифри и букви",
  "Min8Symbols": "Минимум 8 символа.",
  "EmailSendedPleaseCheck": "Регистрацията е почти завършена. Остава само да активирате акаунта си. Моля проверете имейла си {email} там ще получите линк за активиране.",
  "ActivateAccount": "Активиране на акаунт",
  "RegistrationSuccessful": "Успешна регистрация",
  "ActivationCompleted": "Активирането на акаунта Ви е успешно. Можете да влезете в профила си.",
  "ActivationFailed": "Активирането на акаунта е неуспешно. Моля свържете се с администратор. Грешка: ",
  "RegistrationFailed": "Регистрацията е неуспешна. Грешка: ",
  "Users": "Потребители",
  "Favourites": "Любими",
  "Inbox": "Съобщения",
  "PickAdPlatform": "Изберете платформа за реклама",
  "EditProfile": "Редакция на профила",
  "YourSocials": "Вашите мрежи",
  "PageDetails": "Данни за мрежата",
  "AddSocialPage": "Добави социална мрежа",
  "AddPage": "Добави социална мрежа",
  "Save": "Запази",
  "SaveChanges": "Запази промените",
  "DeleteAccount": "Изтриване на акаунт",
  "DeleteAccountConfirm": "Сигурни ли сте че искате да изтриете акаунта си?",
  "AccountDeleteSuccessful": "Изтрихте акаунта си успешно.",
  "Remove": "Премахни",
  "Profile": "Профил",
  "Security": "Сигурност",
  "SecuritySettings": "Защита и вход",
  "PersonalDetails": "Общи настройки на профила",
  "ChangeEmail": "Промяна на мейл",
  "ChangePassword": "Промяна на парола",
  "ChangeAvatar": "Промяна на аватар",
  "JumpToConversation": "Търсете в съобщенията...",
  "ComposeMessage": "Напишете съобщение...",
  "Back": "Назад",
  "NoSocialPages": "Нямате добавени мрежи... все още!",
  "SendMessage": "Съобщение",
  "LogoutSuccessful": "Излязохте успешно",
  "PasswordChangedSuccessfully": "Успешно променихте паролата си",
  "EmailChangedSuccessfully": "Успешно променихте вашия имейл адрес",
  "UserChangedSuccessfully": "Успешно променихте вашите данни",
  "YouHaveNoFavourites": "Нямате любими потребители! Започнете, като плъзнете с пръст върху профил на началната страница и натиснете иконата сърце",
  "Alert": "Внимание",
  "NextActionIrreversible": "Следващото действие може да бъде безвъзвратно!",
  "YouAreTryingToDelete": "Опитвате се да изтриете чата си с потребител:",
  "DoYouWishToContinue": "Желаете ли да продължите?",
  "Delete": "Изтрий",
  "ConversationDeleted": "Успешно изтрихте този чат",
  "Contact": "Контакти",
  "ContactMessage": "Обратната връзка е важна за нас! Отворени сме към вашите идеи и препоръки! Ако имате такива не се колебайте да ни изпратите мейл. Също така, при възникнали технически проблеми или върпоси, свързани с платформата сме на разположение, за да ги решим.",
  "ContactUsAt": "Пишете ни като натиснете бутона под този текст",
  "SendUsAnEmail": "Свържете се с нас",
  "PrivacyPolicy": "Поверителност",
  "ForgottenPassword": "Забравена парола",
  "Confirm": "Потвърдете",
  "EnterEmailForPasswordReset": "Моля въведете имейла с който сте се регистрирали. На него ще получите линк за да обновите паролата си",
  "ResetPasswordEmailLink":"Изпратихме Ви имейл с линк за създаване на нова парола.",
  "Updated":"Промените са направени",

  "validations": {
    "required": "Полето е задължително",
    "numeric": "Полето трябва да бъде число",
    "url": "Невалиден линк",
    "email": "Невалидна ел.поща",
    "minLength": "Полето трябва да бъде поне {min} символа",
    "maxLength": "Полето не трябва да бъде повече от {max} символа",
    "sameAsPassword": "Паролите не съвпадат",
  },
  // translations from djoser
  "Userwiththisemailaddressalreadyexists": "Потребител с такъв имейл вече съществува.",
  "Thepasswordistoosimilartotheusername": "Паролата е сходна с потребителското име.",
  "Thepasswordistoosimilartotheemailaddress": "Паролата е сходна с имейла.",
  "Thispasswordistoocommon": "Паролата е често срещана.",
  "Thetwopasswordfieldsdidntmatch": "Паролите не съвпадат.",
  "Invalid password": "Невалидна парола.",
  "Unabletologinwithprovidedcredentials": "Няма потребител с такива имейл или парола.",

  "потребителстозиemailaddressвечесъществува": "Потребител с такъв имейл вече съществува.",
  "потребителстозиusernameвечесъществува": "Потребител с такова потребителско име вече съществува.",
  "Паролатаемногоподобнанаusername": "Паролата е сходна с потребителското име.",
  "Паролатаемногоподобнанаemailaddress": "Паролата е сходна с имейла.",
},
 en:{
  "NewGenerationAdvertising": "Grow your brand",
  "ReachTheActiveAudience": "Reach the active audience",
  "Home":"Home",
  "TermsOfUse":"Privary and Terms of use",
  "Language":"Language",
  "Menu":"Menu",
  "LogOut":"Log out",
  "Bulgarian":"Bulgarian",
  "English":"English",
  "Cancel":"Cancel",
  "FilterBySocialMedia":"Filter by social media",
  "CreateAccount":"Create account",
  "ForgotYourPassword":"Forgot your password?",
  "SignUp":"Sign up",
  "LogIn":"Log in",
  "DontHaveAnAccount":"Don't have an account?",
  "Password": "Password",
  "Email": "Email",
  "ThisIsYou": "This is you 🙂",
  "Profession": "Profession",
  "Bio": "Bio",
  "PageType": "Social network",
  "Followers": "Followers",
  "Link": "Link",
  "Price": "Price(lv)",
  "PageDescription": "Page description",
  "AlphaNum": "The field musn't contain only integers",
  "FirstName": "First name",
  "LastName": "Last name",
  "CurrentPassword": "Current password",
  "NewPassword": "New password",
  "ConfirmNewPassword": "Confirm new password",
  "CurrentEmail": "Current email",
  "NewEmail": "New email",
  "ConfirmNewEmail": "Confirm new email",
  "PasswordReset": "Password reset",
  "Send": "Send",
  "Username": "Username",
  "RepeatPassword": "Repeat password",
  "UseDigitsAndLetters": "Use digits and letters",
  "Min8Symbols": "Minimum 8 symbols",
  "EmailSendedPleaseCheck": "Registration is almost complete. All that remains is to activate your account. We have sent you an email to {email} with an activation link.",
  "ActivateAccount": "Activate account",
  "RegistrationSuccessful": "Registration successful",
  "ActivationCompleted": "Activation is successful. You can log in now.",
  "ActivationFailed": "Activation failed. Please contact with support. Error: ",
  "RegistrationFailed": "Registration failed. Error: ",
  "Users": "Users",
  "Favourites": "Favourites",
  "Inbox": "Inbox",
  "PickAdPlatform": "Pick a platform to advertise",
  "EditProfile": "Edit profile",
  "YourSocials": "Your Socials",
  "PageDetails": "Page Details",
  "AddSocialPage": "Add social page",
  "AddPage": "Add page",
  "Save": "Save",
  "SaveChanges": "Save changes",
  "DeleteAccount": "Delete account",
  "DeleteAccountConfirm": "Are you sure that you want to delete your account?",
  "AccountDeleteSuccessful": "Account deletion is successful.",
  "Remove": "Remove",
  "Profile": "Profile",
  "Security": "Security",
  "SecuritySettings": "Security Settings",
  "PersonalDetails": "Personal Details",
  "ChangeEmail": "Change Email",
  "ChangePassword": "Change Password",
  "ChangeAvatar": "Change Avatar",
  "JumpToConversation": "Jump to conversation...",
  "ComposeMessage": "Compose message...",
  "Back": "Back",
  "NoSocialPages": "You have no social pages added… yet!",
  "SendMessage": "Send message",
  "LogoutSuccessful": "You've been logged out",
  "PasswordChangedSuccessfully": "You've changed your password successfully",
  "EmailChangedSuccessfully": "You've changed your email successfully",
  "UserChangedSuccessfully": "You've changed your details successfully",
  "YouHaveNoFavourites": "You have no favourites! Add some by swiping left on a user and clicking the hearth!",
  "Alert": "Alert",
  "NextActionIrreversible": "Your next action may be irreversible!",
  "YouAreTryingToDelete": "You are trying to delete conversation with user:",
  "DoYouWishToContinue": "Do you wish to continue?",
  "Delete": "Delete",
  "ConversationDeleted": "Conversation successfully deleted",
  "Contact": "Contact",
  "ContactMessage": "Your feedback is important for us! We are open to suggestions and recommendations, so don't hesitate to contact us! Your feedback is important for us! We are open to suggestions and recommendations, so don't hesitate to contact us! Should you encounter technical difficulties or have any questions about the platform, we are here to help.",
  "ContactUsAt": "Email us by clicking the button below",
  "SendUsAnEmail": "Contact us by email",
  "PrivacyPolicy": "Privacy policy",
  "ForgottenPassword": "Forgotten password",
  "Confirm": "Confirm",
  "EnterEmailForPasswordReset": "Pleace enter your email address so we can send you link to reset you forgotten password",
  "ResetPasswordEmailLink":"Check your email for a link and follow it to reset create new password.",
  "Updated":"Updated",

  "validations": {
    "required": "Required field",
    "numeric": "Value must be numeric",
    "url": "Not valid url",
    "email": "Not valid email",
    "minLength": "Value must be at least {lenght} charecters long",
    "maxLength": "The field must not be more than {max} characters",
    "sameAsPassword": "Passwords doesn't match",
  },
  // translations from djoser
  // translations must be whole error message comming from djoser, without spaces
  "Userwiththisemailaddressalreadyexists": "User with this email address already exists.",
  "Thepasswordistoosimilartotheusername": "The password is too similar to the username.",
  "Thepasswordistoosimilartotheemailaddress": "The password is too similar to the email.",
  "Thispasswordistoocommon": "This password is too common.",
  "Thetwopasswordfieldsdidntmatch": "The two password fields didn't match.",
  "Unabletologinwithprovidedcredentials": "Unable to log in with provided credentials.",
  "Invalid password": "Invalid password.",
}};

export default createI18n({
  legacy: false,
  globalInjection: true,
  locale: process.env.VUE_APP_I18N_LOCALE || 'en',
  fallbackLocale: process.env.VUE_APP_I18N_FALLBACK_LOCALE || 'en',
  messages

})