
import {
  IonApp,
  IonRouterOutlet,
  IonPage
} from '@ionic/vue';
import { defineComponent } from 'vue';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import AppSideMenu from "@/components/AppSideMenu.vue"

export default defineComponent({
  name: 'App',
  components: {
    AppSideMenu,
    IonApp,
    IonRouterOutlet,
    IonPage,
  },
  setup() {
    if (Capacitor.getPlatform() === 'android') {
      StatusBar.setBackgroundColor({ color: '#4dccbd' });
    }
  }
});
