import { isPlatform } from "@ionic/vue";

const API_HOST = isPlatform('android') && !isPlatform('mobileweb')
  ? process.env.VUE_APP_ANDROID_SITE_URL
  : process.env.VUE_APP_SITE_URL;

  const API_URL = process.env.VUE_APP_API_URL;

export const CONFIG = {
    // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
    API_HOST, //control API endpoint in diiferent enviroments
    API_URL,
    PAGINATION_SIZE: 10,
    HOME_REDIRECT: 'home', // control root page (either home, details etc.)
    CHAT_REFRESH_INTERVAL: 30000 // 30 sec == 30000
  }