import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';

import { IonicVue } from '@ionic/vue';

/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';

/* Theme variables */
import './theme/variables.css';

import { defineCustomElements } from '@ionic/pwa-elements/loader';
import storage from "@/composables/localStorage"
import { FETCH_ACCESS_TOKEN } from "@/store/storeTypes";
import i18n from './i18n'

defineCustomElements(window);

const app = createApp(App)
  .use(i18n)
  .use(IonicVue)
  .use(store)
  .use(router);

Promise.all([router.isReady(), storage.isReady()]).then(() => {
  store.dispatch(FETCH_ACCESS_TOKEN).then(()=>{
      app.mount('#app');
  })
});