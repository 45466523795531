import { computed } from 'vue';
import { useStore } from 'vuex';
import { LIST_THREADS } from '@/store/storeTypes';
import { IThread } from '@/types';
import { CONFIG } from '@/config';

export const useNewMessages = () => {
    const store = useStore();
    const hasUnread = computed(() => store.getters.getThreads.some((thread: IThread) => thread.has_unread));

    const getThreads = async () => {
        try {
            await store.dispatch(LIST_THREADS);
        } catch (error) {
            console.error(error);
        }
    };

    let interval: any = null;
    const setupMessagesInterval = () => {
        interval = interval || setInterval(() => {
            getThreads();
        }, CONFIG.CHAT_REFRESH_INTERVAL);
        getThreads();
    };

    const clearMessagesInterval = () => {
        clearInterval(interval);
        interval = null;
    }

    return {
        hasUnread,
        setupMessagesInterval,
        clearMessagesInterval,
    }
};
