import {
  INIT_USER,
  SET_USER,
  SET_FAVOURITES,
  ADD_FAVOURITE_USER,
  ADD_FAVOURITE,
  REMOVE_FAVOURITE_USER,
  REMOVE_FAVOURITE,
  PURGE_USER,
  PURGE_AUTH,
  SET_USER_LANGUAGE,
} from "./storeTypes";
import { getUserProfile } from "@/services/user";
import { createFavourite, deleteFavourite } from "@/services/favourites";
import { IFavourite, UserProfile } from '@/types';
import { useLocalStorage } from "@/composables/localStorage";
import i18n from '@/i18n';
import router from '@/router';

const localSorage = useLocalStorage();
const state: any = {
  defaultSocialPages: ['Instagram', 'TikTok', 'Facebook', 'Youtube', 'Twitch', 'Twitter', 'Linkedin'],
  userProfile: <UserProfile>{}
};

const getters = {
  getFavourites(state: any) {
    return state.userProfile.favourites;
  },
  
  defaultSocialPages(state: any) {
    return state.defaultSocialPages;
  },

  getCurrentUserProfile(state: any) {
    return state.userProfile;
  },
};

const mutations = {
  [SET_FAVOURITES](state: any, favourites: number[]) {
    state.favourites = favourites;
  },
  [ADD_FAVOURITE](state: any, favourite: IFavourite) {
    state.userProfile.favourites = state.userProfile.favourites.concat(favourite);
  },
  [REMOVE_FAVOURITE](state: any, favouriteId: number) {
    state.userProfile.favourites = state.userProfile.favourites
      .filter(({favourite_id}: IFavourite) => favourite_id !== favouriteId);
  },
  [SET_USER](state: any, userProfile: UserProfile) {
    state.userProfile = userProfile;
  },
  [PURGE_USER](state: any) {
    state.userProfile = {};
  }
};

const actions = {
  async [SET_USER_LANGUAGE]() {
    const languageFromStorage = await localSorage.get('language');// if not sotrage get defaults value

    if(languageFromStorage != null && 
        i18n.global.locale.value != languageFromStorage){
      i18n.global.locale.value = languageFromStorage;
    }
  },
  async [INIT_USER]({ commit, dispatch }: any) {
    try {
      
      const userProfile = await getUserProfile();
      
      commit(SET_USER, userProfile);
      await dispatch(SET_USER_LANGUAGE);

    } catch (error) {
      commit(PURGE_AUTH, null, { root: true }) 
      router.push({ path: '/login' })
      console.error('Error INIT_USER ${error}');
    }
  },

  async [ADD_FAVOURITE_USER]({ commit }: any, payload: FormData) {
    try {
      const favourite: IFavourite = await createFavourite(payload);
      commit(ADD_FAVOURITE, favourite);
      // await localSorage.set('favourites', [...getters.getFavourites]);
    } catch (error) {
      console.error('Error found in ADD_FAVOURITE_USER ', error);
    }
  },
  async [REMOVE_FAVOURITE_USER]({ commit }: any, favouriteId: number) {
    try {
      await deleteFavourite(favouriteId);
      commit(REMOVE_FAVOURITE, favouriteId);
      // await localSorage.set('favourites', [...getters.getFavourites]);
    } catch (error) {
      console.error('Error found in REMOVE_FAVOURITE_USER ', error);
    }
  }
}

const store = {
  state,
  getters,
  mutations,
  actions
};
export default store;